<template>
  <ykc-operation-dialog
    title="更新记录"
    size="large"
    :show.sync="showDialog"
    :onClose="onCLose"
    @close-dialog="onCLose"
    top="20vh">
    <div slot="content">
      <custom-list
        warm-tips=""
        :search-data="searchData"
        :search-params="searchParams"
        :show-header="false"
        :is-pure="true"
        table-title=""
        :table-columns="tableColumns"
        :table-operate-buttons="tableOperateButtons"
        :request-method="omManagementApi.remoteUpdateRecords"
        :header-buttons="headerButtons" />
    </div>
  </ykc-operation-dialog>
</template>

<script>
  import CustomList from '@/components/biz/CustomList.vue';
  import { omManagementApi } from '@/service/apis';
  import YkcOperationDialog from '@/components/ykc-ui/operation-dialog/src/main.vue';

  export default {
    name: 'UpdateRecords',
    components: { YkcOperationDialog, CustomList },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      pileCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showDialog: true,
        searchData: [],
        searchParams: {
          pileCode: this.pileCode,
        },
        tableColumns: [
          {
            label: '电桩编码',
            minWidth: '150px',
            prop: 'pileCode',
            scopedSlots: { defaultTitle: () => '' },
          },
          { label: '更新版本号', prop: 'version', scopedSlots: { defaultTitle: () => '' } },
          {
            label: '品牌',
            minWidth: '100px',
            prop: 'brand',
            scopedSlots: { defaultTitle: () => '' },
          },
          { label: '型号', prop: 'model', scopedSlots: { defaultTitle: () => '' } },
          { label: '操作时间', prop: 'operateTime', scopedSlots: { defaultTitle: () => '' } },
          {
            label: '更新结果',
            prop: 'status',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                //   更新结果 1=升级中 2=升级成功 3=升级失败
                if (row.status === 1) {
                  return '升级中';
                }
                if (row.status === 2) {
                  return '升级成功';
                }
                if (row.status === 3) {
                  return '升级失败';
                }
                return '——';
              },
            },
          },
          { label: '操作账号', prop: 'operator', scopedSlots: { defaultTitle: () => '' } },
        ],
        tableOperateButtons: [],
      };
    },
    methods: {
      beforeCancel1(done) {
        console.log('取消的回调 dialog1');
        this.$emit('dialog-close', false);
        done();
      },
      onCLose() {
        // this.showDialog = false;
        this.$emit('dialog-close', false);
      },
      onConfirm(done) {
        console.log('done:', done);
      },
    },
    watch: {
      show(newValue, oldValue) {
        console.log('-> update records show oldValue', oldValue);
        this.showDialog = newValue;
      },
    },
    computed: {
      omManagementApi() {
        return omManagementApi;
      },
      headerButtons() {
        return [];
      },
    },
  };
</script>

<style scoped></style>
